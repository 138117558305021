import styled from 'styled-components';

const HeaderWithLine = styled.h3`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: 500;

  ${({ theme }) => theme.mq.desktop} {
    margin-left: 120px;
    font-size: ${({ theme }) => theme.font.size.xl};
  }

  &:before {
    background-color: #000;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 100px;
    right: 0.5em;
    margin-left: 0;

    ${({ theme }) => theme.mq.desktop} {
      margin-left: -100px;
      width: 220px;
    }
  }
`;

export default HeaderWithLine;
