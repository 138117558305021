import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import {GatsbyImage} from "gatsby-plugin-image";
import SVG from 'react-inlinesvg';
import Hero from '../components/Hero';
import SEO from '../components/SEO';
import HeaderWithLine from '../components/HeaderWithLine';
import ContactFormCom from '../components/ContactFormCom';
import close from '../../static/close.svg';

const StyledWrapper = styled.div`
  padding: 2rem 0;
  background-color: #f5f5f5;
`;

const GridWrapper = styled.div`
  display: grid;
  vertical-align: top;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 400px));
  grid-gap: 2rem;
`;

const SingleImageLink = styled.a`
  text-decoration: none;
`;

const ReferenceDescriptionWrapper = styled.div`
  text-align: center;
`;

const LightboxModal = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  opacity: ${(props) => (props.visible === true ? '1' : '0')};
  visibility: ${(props) => (props.visible === true ? 'visible' : 'hidden')};
  z-index: 9999;
  transition: opacity 0.5s linear;
`;

const LightboxContent = styled.div`
  margin: 15px;
  max-width: 700px;
  width: 100%;
`;

const StyledCloseBtn = styled.div`
  width: 20px;
  height: 20px;
  top: 2%;
  right: 2%;
  position: fixed;
  cursor: pointer;
  opacity: 0.5;
  margin: 0;
  padding: 0;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 20px;
    height: auto;
    margin: auto;
    display: block;
  }

  path {
    fill: white;
  }

  ${({theme}) => theme.mq.desktop} {
    width: 40px;
    height: 40px;

    svg {
      width: 30px;
    }
  }
`;

const PreviousLink = styled.div`
  left: 5%;
  top: 50%;
  transform: translate(0%, -50%);
  position: fixed;
  font-size: 38px;
  cursor: pointer;
  z-index: 9999;
  color: white;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  padding: 1rem;
  opacity: 0.5;
  transition: 0.3s;
  display: ${({disabled}) => (disabled ? 'none' : 'block')};
  background: black;

  &:hover {
    opacity: 1;
  }

  ${({theme}) => theme.mq.desktop} {
    padding: 2rem;
    font-size: 52px;
  }
`;

const NextLink = styled.div`
  right: 5%;
  top: 50%;
  transform: translate(0%, -50%);
  position: fixed;
  font-size: 38px;
  cursor: pointer;
  z-index: 9999;
  color: white;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  padding: 1rem;
  opacity: 0.5;
  transition: 0.3s;
  display: ${({disabled}) => (disabled ? 'none' : 'block')};
  background: black;

  &:hover {
    opacity: 1;
  }

  ${({theme}) => theme.mq.desktop} {
    padding: 2rem;
    font-size: 52px;
  }
`;

export default function ReferencesPage({data}) {
    const [showLightbox, setShowLightbox] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);

    const testimonals = data.testimonials.nodes;

    const handleClick = (e, index) => {
        e.preventDefault();
        setShowLightbox(!showLightbox);
        setSelectedImage(index);
    };

    const closeModal = () => {
        setShowLightbox(false);
    };

    const goBack = () => {
        setSelectedImage(selectedImage - 1);
    };

    const goForward = () => {
        setSelectedImage(selectedImage + 1);
    };

    useEffect(() => {
        const handleKeyUp = (e) => {
            e.preventDefault();

            const {keyCode} = e;
            if (showLightbox) {
                if (keyCode === 37) {
                    // Left Arrow Key
                    if (selectedImage > 0) {
                        setSelectedImage(selectedImage - 1);
                    }
                }
                if (keyCode === 39) {
                    // Right Arrow Key
                    if (selectedImage < testimonals.length - 1) {
                        setSelectedImage(selectedImage + 1);
                    }
                }
                if (keyCode === 27) {
                    // Escape key
                    setShowLightbox(false);
                }
            }
        };

        window.addEventListener('keyup', handleKeyUp, false);

        return () => {
            window.removeEventListener('keyup', handleKeyUp, false);
        };
    }, [showLightbox, selectedImage]);

    return <>
        <SEO
            title="Referencje"
            description="Firma Greener posiada liczne referencje od zadowolonych klientów. Montaż automatycznego nawadniania, zakładanie trawników, sadzenie krzewów i bylin, pielęgnacja zieleni."
        />
        <Hero image={data.singlePage.image} text={data.singlePage.description}/>
        <StyledWrapper>
            <HeaderWithLine>REFERENCJE</HeaderWithLine>
            <GridWrapper>
                {testimonals.map((node, i) => (
                    <div key={node.id}>
                        <div>
                            <ReferenceDescriptionWrapper>
                                <h3>{node.name}</h3>
                            </ReferenceDescriptionWrapper>
                            <SingleImageLink
                                key={node.id}
                                href={node.image.asset.gatsbyImageData.src}
                                onClick={(e) => handleClick(e, i)}
                            >
                                <GatsbyImage image={node.image.asset.gatsbyImageData} alt={node.id}/>
                            </SingleImageLink>
                        </div>
                    </div>
                ))}
            </GridWrapper>
        </StyledWrapper>
        <LightboxModal visible={showLightbox}>
            <LightboxContent>
                <PreviousLink onClick={goBack} disabled={selectedImage === 0}>
                    &#10094;
                </PreviousLink>
                <GatsbyImage
                    image={testimonals.length && testimonals[selectedImage].image.asset.gatsbyImageData}
                    alt={testimonals.length && testimonals[selectedImage].id}/>
                <StyledCloseBtn onClick={closeModal}>
                    <SVG src={close}/>
                </StyledCloseBtn>
                <NextLink
                    onClick={goForward}
                    disabled={selectedImage === testimonals.length - 1}
                >
                    &#10095;
                </NextLink>
            </LightboxContent>
        </LightboxModal>
        <ContactFormCom/>
    </>;
}

export const query = graphql`
  query SinglePageReferenceQuery {
    singlePage: sanitySinglepage(name: { eq: "Referencje" }) {
      id
      name
      description
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
    testimonials: allSanityTestimonials(sort: { fields: name }) {
      nodes {
        id
        name
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
